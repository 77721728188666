import './App.css';
import { useMemo } from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import 'react-mailchimp-email-signup-form/dist/esm/index.css';
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { ReactMailchimpEmailSignupForm } from 'react-mailchimp-email-signup-form';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { createTheme, ThemeProvider } from '@material-ui/core';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  'devnet') as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl('devnet');
const connection = new anchor.web3.Connection(rpcHost);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );
  let navigate = useNavigate();
  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }
  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>

          <li>
            <Link to="/mint/#welcome" onClick={toggleMenu}>MEMBERS</Link>
          </li>
          <li>
            <Link to="/mint/#buynft" onClick={toggleMenu}>BUY YOUR NFT</Link>
          </li>
          <li>
            <Link to="/mint/#gallery" onClick={toggleMenu}>GALLERY</Link>
          </li>
          <li>
            <Link to="/mint/#roadmap" onClick={toggleMenu}>ROADMAP</Link>
          </li>
          <li>
            <Link to="/mint/#charity" onClick={toggleMenu}>CHARITY</Link>
          </li>
          <li>
            <Link to="/mint/#about" onClick={toggleMenu}>ABOUT</Link>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://www.twitter.com/nonfungiblearth" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
              <a href="https://discord.gg/kQrGyKV9jE" target="_blank"><img className="nav-social" src="/icons/discord.svg" alt="" /></a>
              <a href="https://instagram.com/non_fungibleearth" target="_blank"><img className="nav-social" src="/icons/instagram.svg" alt="" /></a>
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>

      {/* <div className="parentimg">
        
              <div className="overlay" ><img src="ABOUT.png" ></img></div></Link>
              <Link to="/mint/#stylespecs"><div className="overlay2"><img src="GALLERY.png"></img></div>
              <Link to="/mint/#buynft"><div className="overlay3"><img src="BUY_YOUR_NFT.png"></img></div></Link>
              <Link to="/mint/#welcome"><div className="overlay4"><img src="MEMBERS.png"></img></div></Link>
              <Link to="/mint/#charity"><div className="overlay5"><img src="CHARITY.png"></img></div></Link>
              <Link to="/mint/#roadmap"><div className="overlay6"><img src="ROADMAP.png"></img></div></Link>
</div>*/}
      <div className="resizeimg hidephone">
        <img src="cave.gif" useMap="#image-map" />

        <map name="image-map">
          <Link to="/mint/#about"><area target="" alt="about" title="about" href="" coords="290,642,454,721" shape="rect" /></Link>
          <Link to="/mint/#roadmap"><area target="" alt="roadmap" title="roadmap" href="" coords="765,654,995,715" shape="rect" /></Link>
          <Link to="/mint/#gallery"><area target="" alt="gallery" title="gallery" href="" coords="66,289,256,354" shape="rect" /></Link>
          <Link to="/mint/#welcome"><area target="" alt="members" title="members" href="" coords="469,337,725,405" shape="rect" /></Link>
          <Link to="/mint/#buynft"><area target="" alt="buynft" title="buynft" href="" coords="434,149,755,218" shape="rect" /></Link>
          <Link to="/mint/#charity"><area target="" alt="charity" title="charity" href="" coords="932,161,1124,221" shape="rect" /></Link>
        </map>
      </div>
      <div className="resizeimg showphone">
        <img src="phonecave.gif" useMap="#image2-map" />

        <map name="image2-map">
          <Link to="/mint/#about"><area target="" alt="about" title="about" href="" coords="101,220,158,244" shape="rect" /></Link>
          <Link to="/mint/#roadmap">  <area target="" alt="roadmap" title="roadmap" href="" coords="260,223,342,243" shape="rect" /></Link>
          <Link to="/mint/#gallery"> <area target="" alt="gallery" title="gallery" href="" coords="18,100,90,119" shape="rect" /></Link>
          <Link to="/mint/#welcome">  <area target="" alt="members" title="members" href="" coords="162,116,243,134" shape="rect" /></Link>
          <Link to="/mint/#buynft"> <area target="" alt="buynft" title="buynft" href="" coords="150,54,263,72" shape="rect" /></Link>
          <Link to="/mint/#charity">  <area target="" alt="charity" title="charity" href="" coords="319,55,383,73" shape="rect" /></Link>
        </map>
      </div>


      <section id="welcome">
        <div className="textleft">
          <h1>WELCOME TO THE JUNGLE</h1>
          <br></br><br></br>
          <p>We have created the ‘Rumble in the Jungle’ NFT collection to help protect jungles and rainforests around the world, as well as the animals that reside in them — 100% of profits will be donated to charity to protect these habitats.

            <div className="hideplus600">
              <div className="grid">
                <div className="row">
                  <img src="GRID_1.jpg" alt="" />
                  <img src="GRID_2.jpg" alt="" />
                </div>
                <div className="row">
                  <img src="GRID_3.jpg" alt="" />
                  <img src="GRID_4.jpg" alt="" />
                </div>
              </div>
            </div>
            <br></br><br></br>
            Dressed to impress, NFE tigers inhabit the Solana blockchain. We have chosen Solana as it continues to be carbon neutral, plus a single Solana transaction uses less energy than the amount required to complete two Google searches. We will be unleashing 3,900 tiger NFTs, as this is the number estimated to be left in the wild.
            <br></br><br></br>
            By purchasing your tiger NFT, not only will you help to protect the species and the environment; you will own an NFT artwork which also grants you access to our exclusive NFE Membership. Roadmap activities will be exclusive to our members, alongside access to special experiences hosted by the crew.</p>
        </div>
        <div className="rightpart">
          <div className="hide600">
            <div className="grid">
              <div className="row">
                <img src="GRID_1.jpg" alt="" />
                <img src="GRID_2.jpg" alt="" />
              </div>
              <div className="row">
                <img src="GRID_3.jpg" alt="" />
                <img src="GRID_4.jpg" alt="" />
              </div>
            </div>
          </div>
          <p className="underimg">NOTE: 10 tigers are being witheld from the sale.
            These will be used for giveaways and for the
            creators' NFE memberships.</p>
        </div>
      </section>

      <section id="buynft">
        <div className="buytiger">
          <div className="textbuy">
            BUY A TIGER
            <ThemeProvider theme={theme}>
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <WalletDialogProvider>
                    <Home
                      candyMachineId={candyMachineId}
                      connection={connection}
                      txTimeout={DEFAULT_TIMEOUT}
                      rpcHost={rpcHost}
                      network={network}
                      error={error}
                    />
                  </WalletDialogProvider>
                </WalletProvider>
              </ConnectionProvider>
            </ThemeProvider>
          </div>

          <div className='newtobuy'>
            <p className="lineheight"> 
              New to buying NFTs? <br />
              Check out our <a href="./NFT GUIDE.pdf" target="_blank">3 step guide</a>
              <br /> 
            </p>
            <p className="chainpr">
            1 SOL (~$35)<br />
            </p>
          </div>
          <a href="./NFT GUIDE.pdf" target="_blank"><img className='guide' src="guide.png" alt="" /></a>
        </div>
        <hr></hr>
      </section>
      <section id="charity">
        <div className="stylespecs">
          <div className="textleft">
            <br></br>
            <h1>CHARITY</h1>
            <br></br><br></br>
            <p>With each tiger purchase, 100% of profits are donated to our two charities.
              <br /><br />
              <a href="https://amazonwatch.org/" target="_blank"><span>Amazon Watch</span></a>  is a leading environmental organisation, protecting the rainforest and our climate in solidarity with Indigenous peoples. <a href="https://amazonwatch.org/" target="_blank">More info...</a>
              <br /><br />
              <a href="https://www.rainforestconcern.org/" target="_blank"><span>Rainforest Concern</span></a>  is a British charity protecting rainforests and other crucial natural habitats, working with communities and organisations across eight countries. <a href="https://www.rainforestconcern.org/" target="_blank">More info...</a>
              <br /><br />
              Any secondary sales profits will go towards future charitable initiatives.


            </p></div>
          <div className="rightpart">
            <br /><br />
            <div className="charities">
              <a href="https://amazonwatch.org/" target="_blank"><img src="amazonwatch.png" alt="" /></a>
              <a href="https://www.rainforestconcern.org/" target="_blank"><img src="rainforest.png" alt="" /></a>
            </div>
          </div>

        </div>
        <hr></hr>
      </section>

      <section id="roadmap">
        <div className="roadmap-part">
          <div className="textleft">
            <br></br>
            <h1>ROADMAP</h1>
            <br></br><br></br>

            <p><div className="road-item">
              <div className="percentage">10% </div>	<div className="textroad"> We throw Lorne into the Thames.<br></br><br></br>
              </div>
            </div>

              <div className="road-item">
                <div className="percentage">30%</div> <div className="textroad">Competition between holders for the best outfits to be created into 5 full body tiger NFTs.<br></br><br></br>
                </div>
              </div>
              <div className="road-item">
                <div className="percentage">50% </div><div className="textroad">	Eco-friendly merch unleashed.<br></br><br></br>
                </div>
              </div>

              <div className="road-item">
                <div className="percentage">80%</div>	<div className="textroad">	5 lucky holders chosen at random to meet a tiger in real life, as well as other member exclusive experiences.<br></br><br></br>
                </div></div>
              <div className="road-item">
                <div className="percentage">100%</div><div className="textroad">We launch the NFE Environmental Fund, funding our holders’ most innovative environmental project proposals.
                </div></div>
              <br></br><br></br>
              And after all that, we prepare for the launch of Season 2 — Ice Ice Baby…
              <br></br><br></br>
              Each Rumble in the Jungle NFT holder will be able to submit an outfit of their own, the best of which will be used for the NFTs of our second season!
            </p>
          </div>
          <div className="rightpart">
            <br /><br />
            <img src="roadmap.jpg"></img>
          </div>
        </div>
        <hr></hr>
      </section>


      <section id="about">
        <div className="about">
          <div className="textleft">
            <br></br>
            <h1>ABOUT</h1>
            <br></br><br></br>
            <p>We are four schoolmates who launched NFE with the aim of raising awareness for our Non-Fungible Earth that we seem to so easily take for granted. This collection is an opportunity to help the environment and protect the tigers, whilst simultaneously entering into the new and exciting world of NFTs. Although we are starting off with the appealing weather of the tropics, we plan on launching new seasons (NFT collections) in the future. Each season will be dedicated to helping a different habitat.
              <br /><br />
              Our artist-in-residence, Kerry Eggleton, has been an established anthropomorphic artist for 12 years. Having worked on commissions for the likes of Annabel’s Mayfair and prestigious tailoring house Cifonelli, we hoped she would agree to join us on our mission.
              <br /><br />
              Find out more about Kerry’s artwork at <a href="https://www.mashka.co.uk/" target="_blank">www.mashka.co.uk</a>
            </p></div>
          <div className="textleft">
            <br></br>
            <h1>THE CREW</h1>
            <br></br><br></br>
            <p><span className="greentext"><b>ILIYA</b></span><i> not here to mess around</i></p>
            <br className="hidephone"></br><br></br>
            <p><span className="greentext"><b>AMOGH</b></span><i> always fashionably late</i></p>
            <br className="hidephone"></br><br></br>
            <p><span className="greentext"><b>JEREMY</b></span><i> bad cop</i></p>
            <br className="hidephone"></br><br></br>
            <p><span className="greentext"><b>LORNE</b></span><i> the daredevil</i></p>
            <br className="hidephone"></br><br></br>
            <p><span className="greentext"><b>KERRY</b></span><i> artist-in-residence</i></p>

          </div>

        </div>

      </section>
      <section id="gallery">
        <hr></hr>
        <div className="gallery">
          <div className="textleft">
            <br></br>
            <h1>GALLERY</h1>
            <br></br><br></br>
            <p>
              All of our tigers have their own personalities, some more eccentric than others, that are expressed through a selection from 78 different traits. Each is unique. Your tiger will be randomly selected for you when purchased.
              <br /><br />
              The NFE tigers are Solana tokens on the Solana blockchain and hosted on Arweave.
              <br /><br />
              Our artist Kerry Eggleton has created 16 styles of headwear, 7 sets of eyes, 12 types of eyewear, 27 outfits, 6 fur colours and 10 backgrounds.
              <br /><br />
              16 x 7 x 12 x 27 x 6 x 10 = 2,177,280 possible combinations of which only 3,900 will be generated for ‘Rumble in the Jungle’.
              <br /><br />
              Check out the 78 traits below...


            </p></div>
          <div className="gallerygrid">
            <img src="GALLERY/1.jpg" alt="" />
            <img src="GALLERY/2.jpg" alt="" />
            <img src="GALLERY/3.jpg" alt="" />
            <img src="GALLERY/4.jpg" alt="" />
            <img src="GALLERY/5.jpg" alt="" />
            <img src="GALLERY/6.jpg" alt="" />
            <img src="GALLERY/7.jpg" alt="" />
            <img src="GALLERY/8.jpg" alt="" />
            <img src="GALLERY/9.jpg" alt="" />
            <img src="GALLERY/10.jpg" alt="" />
            <img src="GALLERY/11.jpg" alt="" />
            <img src="GALLERY/12.jpg" alt="" />
            <img src="GALLERY/13.jpg" alt="" />
            <img src="GALLERY/14.jpg" alt="" />
            <img src="GALLERY/15.jpg" alt="" />
            <img src="GALLERY/16.jpg" alt="" />
            <img src="GALLERY/17.jpg" alt="" />
            <img src="GALLERY/18.jpg" alt="" />
            <img src="GALLERY/19.jpg" alt="" />
            <img src="GALLERY/20.jpg" alt="" />
            <img src="GALLERY/21.jpg" alt="" />
            <img src="GALLERY/22.jpg" alt="" />
            <img src="GALLERY/23.jpg" alt="" />
            <img src="GALLERY/24.jpg" alt="" />
            <img src="GALLERY/25.jpg" alt="" />
            <img src="GALLERY/26.jpg" alt="" />
            <img src="GALLERY/27.jpg" alt="" />
            <img src="GALLERY/28.jpg" alt="" />
            <img src="GALLERY/29.jpg" alt="" />

          </div>
          <div className="textleft">
            <br /><br />
            <p><i> ‘?’ watermarks will not be present on the actual NFTs and these combinations may not be part of the 3,900.</i></p>
          </div>
        </div>

      </section>

      <footer className="mintfoot">
        <div className="nav-container">
          <ReactMailchimpEmailSignupForm
            elementId="first-email-signup-form"
            url="https://nonfungibleearth.us14.list-manage.com/subscribe/post?u=14f3ff63cdc0b4c1c9754eb68&amp;id=a98c106ec1"
            title="Join the Jungle Bulletin"
          />    <div className="tcs">
            <div className="social-icons">

              <a href="https://www.twitter.com/nonfungiblearth" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
              <a href="https://discord.gg/kQrGyKV9jE" target="_blank"><img className="nav-social" src="/icons/discord.svg" alt="" /></a>
              <a href="https://instagram.com/non_fungibleearth" target="_blank"><img className="nav-social" src="/icons/instagram.svg" alt="" /></a>
            </div>
            <h3 className="hide-800"><a href="tsandcs.pdf" target="_blank">Ts & Cs</a></h3>
          </div>
        </div>
        <h3 className="hide800"><a href="tsandcs.pdf" target="_blank">Ts & Cs</a></h3>
        <h2><a href="https://www.nfelimited.com" target="_blank">© Non-Fungible Earth Ltd</a></h2>
      </footer>
    </div>
  );
};

export default App;
