import React from "react"
import { useNavigate } from "react-router-dom";
import 'react-mailchimp-email-signup-form/dist/esm/index.css';
import { ReactMailchimpEmailSignupForm } from 'react-mailchimp-email-signup-form';


function ClubPage() {
    let navigate = useNavigate();
  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }
    return (

        <div className="headerclub" >
            <div className="mainbanner" onClick={() => {
            navigate("/mint");
        }} >
           
            </div>
        
            <div className="club-text">
                <h2>RUMBLE IN THE JUNGLE</h2>
                <p>A characterful collection of NFT tigers which not only look good, but actually do good for the planet. The cave is always open, so come on in.</p>
            </div>

            <hr></hr>
            <footer>
           <div className="nav-container">
      <ReactMailchimpEmailSignupForm
    elementId="first-email-signup-form"
    url="https://nonfungibleearth.us14.list-manage.com/subscribe/post?u=14f3ff63cdc0b4c1c9754eb68&amp;id=a98c106ec1"
    title="Join the Jungle Bulletin"
  />    <div className="tcs">
                <div className="social-icons">

                    <a href="https://www.twitter.com/nonfungiblearth" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
                    <a href="https://discord.gg/kQrGyKV9jE" target="_blank"><img className="nav-social" src="/icons/discord.svg" alt="" /></a>
                    <a href="https://instagram.com/non_fungibleearth" target="_blank"><img className="nav-social" src="/icons/instagram.svg" alt="" /></a>
                </div>
                <h3 className="hide-800"><a href="tsandcs.pdf" target="_blank">Ts & Cs</a></h3>
                </div>
                </div>
                <h3 className="hide800"><a href="tsandcs.pdf" target="_blank">Ts & Cs</a></h3>
                <h2><a href="https://www.nfelimited.com" target="_blank">© Non-Fungible Earth Ltd</a></h2>
            </footer>
        </div>


    );
}
export default ClubPage;