import "./App.css";
import React from "react"
import MintPage from './Mintpage'
import ClubPage from './ClubPage'
import { Routes, Route, HashRouter as Router, Link } from 'react-router-dom';
function toggleMenu() {
  const menu = document.getElementById("mobileNavContainer")!;
  menu.classList.toggle("open-menu");
  console.log("pressed");
}
function App() {
  return <div className="App">
 
      <nav>
        <div className="nav-container">
          <a href = "/"><img className="nav-logo" src="/img/logo.png" alt="" /></a>
         
         <div className="nav-title hide-800">
           
         <h3>Non-Fungible Earth</h3>
          <div className="social-icons hide-800">
            
              <a href = "https://www.twitter.com/nonfungiblearth" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a> 
              <a href = "https://discord.gg/kQrGyKV9jE" target="_blank"><img className="nav-social" src="/icons/discord.svg" alt="" /></a>
              <a href = "https://instagram.com/non_fungibleearth" target="_blank"><img className="nav-social" src="/icons/instagram.svg" alt="" /></a>
          </div>
          </div>
        </div>
      </nav>
    <Router>
      <Routes>
        <Route path="/mint" element={<MintPage />}/>
        <Route path="/" element={<ClubPage />}/>
      </Routes>
    </Router>
  </div>
}

export default App;
